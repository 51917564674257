.footerContainer{
    width: 100%;
    height: 400px;
    background-color: rgb(1, 46, 70);
    text-align: center;
}

.footerTitle{
    padding-top: 20px; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: rgb(254, 254, 254);
}

.footerIcons{
    position: relative;
    left: 38%;
    width: 20%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footerAddress{
    color: white;
    word-spacing: 0.5rem;
    line-height: 2.5rem;
}
.footerCopyRight{
    color: white;
    position: relative;
    bottom: -60px;
}