.headings{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
  
}

.headings hr {
    border: 0;
  height: 5px; 
  background-color: rgb(52, 138, 244); 
   width: 250px;
   margin: auto;
}

.title{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 30px;
}

.subtitle{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 500;
    margin: 5px;
    font-size: 18px;
}

.subtitle1{
    line-height: 200%;
    word-spacing: 0.5rem;
    font-size: 20px;
    padding: 0 20px 0 20px;
    margin-bottom: 20px;
}
.logCont{
    margin: 25px 20px 0 20px;
    text-align: start;
}

.hr{
    border: 0;
    height: 2px; 
    background-color: rgb(206, 208, 209); 
     width: 100%;
     margin: auto;
}


.point {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.pointcircle {
    width: 5px; 
    height: 5px; 
    background-color: black;
    border-radius: 50%; 
    margin-right: 10px; 
    margin-top: 20px;
    
}

.subtitle11{
    line-height: 200%;
    word-spacing: 0.5rem;
    font-size: 20px;
    padding: 0 10px 0 10px;
    margin-bottom: 5px;
}


@media screen and (max-width : 900px) and (min-width:740px){
   
    .subtitle1{
        line-height: 200%;
        word-spacing: 0.3rem;
        font-size: 15px;
        width: 100%;
    }
    .servicesDIV{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        
    }
    .title{
        font-size: 20px;
    }
    .headings hr {
        border: 0;
      height: 5px; 
      background-color: rgb(52, 138, 244); 
       width: 50px;
       margin: auto;
    }
    .subtitle11{
        line-height: 200%;
        word-spacing: 0.3rem;
        font-size: 15px;
        width: 100%;
        padding: 0 10px 0 10px;
        margin-bottom: 5px;
    }
  }
  
  
  @media screen and (max-width:740px){
    .title{
        font-size: 15px;
    }
    .subtitle1{
        line-height: 200%;
        word-spacing: 0.2rem;
        font-size: 12px;
        width: 100%;
    }
    .servicesDIV{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        
    }
    .subtitle11{
        line-height: 200%;
        word-spacing: 0.2rem;
        font-size: 12px;
        width: 100%;
        padding: 0 10px 0 10px;
        margin-bottom: 5px;
    }
  }
