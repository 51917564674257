.headings{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
  
}

.headings hr {
    border: 0;
  height: 5px; 
  background-color: rgb(52, 138, 244); 
   width: 250px;
   margin: auto;
}

.title{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 30px;
}
.subtitle{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 500;
    margin: 5px;
    font-size: 18px;
}
.logCont{
    display: flex;
    justify-content: space-between;
}

.subtitle1{
    line-height: 200%;
    word-spacing: 0.5rem;
    font-size: 20px;
    width: 70%;
}

.logContImg{
   width: 25%;
}

.servicesDIV{
   display: grid;
   grid-template-columns: repeat(4,1fr);
   row-gap: 20px;
   column-gap: -20px;
   text-align: center;
}

.servicesDIV div{
    width: 200px;
    height: 200px;
    border: 2px solid rgba(211, 211, 211, 0.832);
    border-radius: 4px;
    margin: 2px;
}

.servicesDIV div:hover{
    border: 2px solid rgba(114, 142, 240, 0.832);
    cursor: pointer;
}

.servicesDIV div img {
    width: 60%;
    margin: auto;
}

.servicesDIV div p {
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 70%;
    margin: auto;
    padding-top: 30%;
    color: grey;
}

.contact{
    margin-top: 20px;
    word-spacing: 0.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: grey;
}

.email {
    color: #007bff;
    text-decoration: none;
  }
  .email:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media screen and (max-width : 900px) and (min-width:740px){
    .logContImg{
        display: block;
    }
    .subtitle1{
        line-height: 200%;
        word-spacing: 0.3rem;
        font-size: 15px;
        width: 100%;
        /* background-color: #007bff; */
    }
    .servicesDIV{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        
    }
  }
  
  
  @media screen and (max-width:740px){
    .logContImg{
        display: block;
    }
    .subtitle1{
        line-height: 200%;
        word-spacing: 0.2rem;
        font-size: 12px;
        width: 100%;
        /* background-color: #007bff; */
    }
    .servicesDIV{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        
    }
  }
