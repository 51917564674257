 .headings{
    text-align: center;
    margin-bottom: 50px;
}

.contactContainer{
    background-color: rgb(232, 232, 232);

}

.regards p{
  line-height: 120%;
  word-spacing: 0.5rem;
  font-size: 20px;
  padding: 0 0px 10px 10px;
  font-weight: 400;
  margin-bottom: 5px;
}

.headings hr {
    border: 0;
  height: 5px; 
  background-color: rgb(52, 138, 244); 
   width: 200px;
   margin: auto;
}

.title{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 30px;
} 

.imgs{
    width: 35%;
    border-radius: 50%;
  }

  .person{
    width: 100%;
    height: 300px;
    margin: auto;
    
  }

  .person h2 {
    font-size: 40px;
    font-weight: 500;
    font-family: Inter, Helvetica, Arial, Verdana, sans-serif;
    color: rgb(45, 46, 46);
    text-align: center;
  }

  .person h2 span {
    color: rgb(28, 28, 92);
  }

  .personContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    margin-top: 10px;
    width: 70%;
    /* background-color: #007bff; */
  }
  .personsubContainer{
    width: 22%;
    margin: auto;
    /* background-color: rgb(231, 137, 14); */
    /* text-align: center; */
  } 
  .email {
    color: #007bff;
    text-decoration: none;
  }
  .email:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .personsubContainer h3 {
    font-size: 20px;
  }
  .personsubContainer p {
    margin-top: 5px;
  }


  @media screen and (max-width : 900px) and (min-width:740px){
   
  
    .person h2 {
      font-size: 30px;
    }
  .imgs{
    width: 55%;
    border-radius: 50%;
  }
  .personsubContainer{
    width: 30%;
    margin: auto;
  }
  .regards p{
    line-height: 120%;
    word-spacing: 0.3rem;

  }
  }

  
  
  
  @media screen and (max-width:740px) and (min-width:500px){
    .personsubContainer{
      width: 30%;
      margin: auto;
    }
  
    .person h2 {
      font-size: 25px;
      font-weight: 500;
    }
    .regards p{
      line-height: 120%;
      word-spacing: 0.3rem;
  
    }
  }

  @media screen and (max-width:500px) {
    .personsubContainer{
      width: 60%;
      margin: auto;
    }
    .person h2 {
      font-size: 25px;
      font-weight: 500;
    }
    .regards p{
      line-height: 120%;
      word-spacing: 0.3rem;
  
    }
  }