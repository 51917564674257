.Nav{
   width: 100%;
   height: 100px;
    color: rgb(1, 46, 70);
    font-size: 20px;
    font-weight: 500;
    display: flex;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}
.NavImgDiv p{
    font-size: 15px;
}

.NavHeading{
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 18px;
    /* background-color: rgb(248, 149, 1); */
}

.NavImg{
   width: 10%;
   margin-left: 20%;
   cursor: pointer;
   /* padding-top: 20px; */
}
.NavImgDiv{
    width: 80%;
    height: 100%;
    /* background-color: blueviolet; */
    display: flex;
    align-items: center;
}

.sliders{
    height: 500px;
}

.HamburgerIcon{
    display: flex;
    flex-direction: column;
    color: rgb(1, 46, 70);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    align-items: center;
    margin-top: 20px;
}

.HamburgerIcon a{
    margin-top: 20px;
}

.Drawer{
    background-color: brown;
}

.icon{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(1, 46, 70);
    font-size:x-large;
    margin-top: 28px;
}

.NavTitle{
    font-size: 45px;
    color: brown;
}

